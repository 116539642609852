// for collapsible object
// https://alligator.io/css/collapsible

//looks for hashtag anchor and opens corresponding dropdown ID
function openInputDropdown() {
  var idWithoutHash = window.location.hash.substring(1);

  var div = document.getElementById(idWithoutHash);

  if (div != null) {
    var inputs = div.getElementsByTagName('input');
    var checkbox = inputs[0];
    checkbox.checked = true;
  }
}

window.addEventListener('pageshow',openInputDropdown);
window.addEventListener('hashchange',openInputDropdown);


// const openInputDropdown = () => {
//   let idWithoutHash = window.location.hash.substring(1);
//
//   let div = document.getElementById(idWithoutHash);
//
//   if (div != null) {
//     let inputs = div.getElementsByTagName('input');
//     let checkbox = inputs[0];
//     checkbox.checked = true;
//   }
// };


// for aria
let ariaCollapsibleLabel = document.querySelectorAll('.collapsible-toggle-label');

Array.from(ariaCollapsibleLabel).forEach(label => {
  label.addEventListener('keydown', e => {
    // 32 === spacebar
    // 13 === enter
    if (e.which === 32 || e.which === 13) {
      e.preventDefault();
      label.click();
    }
  });
});