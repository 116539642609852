import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
//require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
import './lib/foundation-explicit-pieces';


$(document).foundation();

require ('./lib/collapsible-object-dropdown'); // dropdown for customer reviews and studies
//require ('./lib/plus-minus-button'); //add to cart
//require ('./lib/masked-input'); //phone nos and cc

//
//
//JQuery is not global, so must add the following (two lines) at the top of each library file which uses jQuery (to initialize jQuery for that file).
// see: https://zendev.com/2017/09/05/front-end-development-kickstarter-zurb-template.html#javascript
//
//import $ from 'jquery';
//window.jQuery = $;